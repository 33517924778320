'use client'

import * as React from 'react'
// import Script from 'next/script'
import Navbar from '@/app/components/Navbar'
import Footer from '@/app/components/Footer'
import CookieConsent from '@/app/components/CookieConsent'

interface Props {
  children: React.ReactNode
}

export default function ClientLayout({ children }: Props): React.ReactElement {
  return (
    <>
      {/* <Script */}
      {/*  async */}
      {/*  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5261304523430352" */}
      {/*  crossOrigin="anonymous" */}
      {/* /> */}
      <main className="relative mx-auto min-h-screen w-full bg-white dark:bg-neutral-800">
        <Navbar />
        <main id="content" className="w-full pb-6">
          <div className="grid">
            <div className="col-start-1 row-start-1 fill-neutral-100 object-cover dark:fill-neutral-900">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100">
                <path d="M1000 0H0v52C62.5 28 125 4 250 4c250 0 250 96 500 96 125 0 187.5-24 250-48V0Z" />
              </svg>
            </div>
            <div className="col-start-1 row-start-1">{children}</div>
          </div>
        </main>
        <Footer />
        <CookieConsent />
      </main>
    </>
  )
}
